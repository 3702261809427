import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>POZNAJ RÓŻNICE MIĘDZY PRODUKTAMI FINISH</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkty/',
    label: 'SPRAWDŹ WSZYSTKIE PRODUKTY',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend: '',
      rows: [
        {
          text: 'Skuteczne i wydajne czyszczenie',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt012bbc1bc45bb62a/6751a4215b42637a7d305f61/Skuteczne_i_wydajne_czyszczenie.webp',
        },
        {
          text: 'Nabłyszczenie naczyń',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltf05ff5048fa8f0bc/6751a4329eb0a063ed095967/Nabłyszczenie_naczyn.webp',
        },
        {
          text: 'Oszczędzanie wody',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt49a6cb289e47cf63/6751a4421b4776d9f7cc462a/Oszczedzanie_wody.webp',
        },
        {
          text: 'Wstępne namaczanie',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8bfb50bdd56d5807/6751a678a22d0d488f67b345/Wstepne_namaczanie.webp',
        },
        {
          text: 'Skuteczne w programie eco',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt5c003755e9d39feb/6751a6789d99cf243e01ed35/Skuteczne_w_programie_eco.webp',
        },
        {
          text: 'Ochrona przed osadzaniem kamienia',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt35e11e66bac9b814/6751a678b0570db1484ef16f/Ochrona_przed_osadzaniem_kamienia.webp',
        },
        {
          text: 'Usuwanie najcięższych zabrudzeń już przy 1. użyciu',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt357c77e2b912016a/6751a678dc2bcc0d7dc3bfc7/Usuwanie_najciezszych_zabrudzen_juz_przy_1_uzyciu.webp',
        },
        {
          text: 'Ochrona szklanych naczyń',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltca580b8e0235de2a/6751a678d3a02d6f277552b2/Ochrona_szklanych_naczyn.webp',
        },
        {
          text: 'Skuteczne działanie w niskich temperaturach',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt8a60a7ccb627a315/6751a6789d99cf25cc01ed31/Skuteczne_dzialanie_w_niskich_temperaturach.webp',
        },
        {
          text: 'Skuteczne usuwanie zaschniętych zabrudzeń',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt808216de24d23730/6751a678475da718d280008f/Skuteczne_usuwanie_zaschnietych_zabrudzen.webp',
        },
        {
          text: 'Ochrona srebra',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltb60aca3fa02f0ba9/6751a6780c132e0be5955971/Ochrona_srebra.webp',
        },
        {
          text: 'Skuteczne usuwanie przypalonych zabrudzeń',
          image:
            'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt0c64845d6830fc06/6751a678bf3df1423fe2ab01/Skuteczne_usuwanie_przypalonych_zabrudzen.webp',
        },
        {
          text: 'CyclesyncTM',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4228905f1347b8d8/66a8bd75f095557978747345/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5a1d9e23a9f38b42/66a8bab8f2443983a573bdfc/ultimate-plus-x2.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkty/detergenty/kapsulki/ultimate-plus/45-lemon/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt885b71b3dca45c5a/66a8bd76b3baaf4f6397e363/ultimate-x2.png',
          productName: 'ULTIMATE',
          productLink: '/produkty/detergenty/kapsulki/ultimate/40-fresh/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt9a8d029b63113ce2/66a8bd75fb2f0f64f438eba8/quantum-x2.png',
          productName: 'QUANTUM',
          productLink: '/produkty/detergenty/kapsulki/quantum/60-lemon/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf7e1f373bd749614/66a8bd753d47313511294ec4/power-x2.png',
          productName: 'POWER',
          productLink: '/produkty/detergenty/tabletki/power/50-fresh/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductDifferencesMockUA = {
  header: '<h2>ДІЗНАЙТЕСЯ ПРО ВІДМІННОСТІ МІЖ ПРОДУКТАМИ FINISH</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkty/',
    label: 'ПЕРЕГЛЯНУТИ ВСІ ПРОДУКТИ',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>очищення затверділих плям при повному завантаженні посудомийної машини.</li>\n<li><sup>**</sup>порівняно з Finish Power на основі середньої ваги.</li>\n<li><sup>***</sup>ефективно видаляє пригорілі залишки їжі.</li>\n<li><sup>****</sup>інгредієнт: ензим</li>\n</ul>',
      rows: [
        {
          text: 'Глибоке очищення',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt82888abe65a3cf92/66a8bd75f24439cde173be08/clean.svg',
        },
        {
          text: 'Сяючий блиск',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4068f74db0e98470/66a8bdf6b8115e2482a37a72/shine.svg',
        },
        {
          text: 'Проти затверділих протягом 48 годин залишків їжі<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4b1a83960fae2f42/66a8bd75b8115e47cca37a6d/dried-on.svg',
        },
        {
          text: 'Ефективність проти жиру',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltef4110afe98d3440/66a8bdf6f24439951073be0f/tough-on-grease.svg',
        },
        {
          text: 'Не потребує ополіскування',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltb35fc648c6362147/66a8bdf61097ec6bcaa86bf5/skip-the-rinse.svg',
        },
        {
          text: 'Не потребує розпакування',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt906d9e1af7cf7cf8/66a8bd7575e8676dacd73cd9/wrapper-free-tabs.svg',
        },
        {
          text: 'Швидке розчинення',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt84a3d1465f181c87/66a8bdf67da9b43d0cb481c0/fast-dissolving.svg',
        },
        {
          text: 'Формула містить менше хімічних речовин<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt0b422bd29369e3e4/66a8bdf6739577514719fa1a/less-chemical-weight.svg',
        },
        {
          text: 'Захист скла',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blta20864530554004a/66a8bd751a892d24844a3ac8/glass-protection.svg',
        },
        {
          text: 'Свіжий аромат',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt95a23ce6c566f108/66a8bd7517cd4f0b596f724a/freshness-boost.svg',
        },
        {
          text: 'Усуває пригорілі залишки їжі<sup>***</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf4e3970c7de0d7ea/66a8bd7506435dc50cddea0b/burnt-on-stains.svg',
        },
        {
          text: 'Поступове вивільнення інгредієнтів<sup>****</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4228905f1347b8d8/66a8bd75f095557978747345/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5a1d9e23a9f38b42/66a8bab8f2443983a573bdfc/ultimate-plus-x2.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkty/detergenty/kapsulki/ultimate-plus/45-lemon/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt885b71b3dca45c5a/66a8bd76b3baaf4f6397e363/ultimate-x2.png',
          productName: 'ULTIMATE',
          productLink: '/produkty/detergenty/kapsulki/ultimate/40-fresh/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt9a8d029b63113ce2/66a8bd75fb2f0f64f438eba8/quantum-x2.png',
          productName: 'QUANTUM',
          productLink: '/produkty/detergenty/kapsulki/quantum/60-lemon/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf7e1f373bd749614/66a8bd753d47313511294ec4/power-x2.png',
          productName: 'POWER',
          productLink: '/produkty/detergenty/tabletki/power/50-fresh/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt535ec2a545e36726/677eab0ea53bf60c2ece270c/Finish_Ultimate_Finish_PL.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkty/detergenty/kapsulki/ultimate/40-fresh/',
        text: 'SPRAWDŹ ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte599822b028d68d8/677eab0e966e6917d5fe05fd/dried-on-stains-outline-red_(1).webp',
          name: 'USUWA ZASCHNIĘTE ZABRUDZENIA',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt50c6add3c012a00f/677eab0eb67955d9b187adde/tough-on-grease-outline-red.webp',
          name: 'SKUTECZNY NA TŁUSZCZ',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt32e4d8727a4e0322/677eab0e43d191501d613128/Finish_Ultimate_Finish_Plus_PL.webp',
      cardStyle: 'black',
      button: {
        link: '/produkty/detergenty/kapsulki/ultimate-plus/45-lemon/',
        text: 'SPRAWDŹ NASZ NAJLEPSZY ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfffcfb30fa4a7d54/677eab0eae593823833f031f/burnt-on-stains-outline-gold.webp',
          name: 'USUWA PRZYPALONE ZABRUDZENIA',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltdc26c3a34f7107f0/677eab0e5fc75374bf521a08/tough-on-grease-gold-outline-gold.webp',
          name: 'SKUTECZNY NA TŁUSZCZ',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt31d1a3b88b112564/677eab0ee634b6fe5829a8cf/extra-cleaning-power-outline-gold_(1).webp',
          name: '<strong>3X</strong><br/>WIĘKSZA MOC CZYSZCZENIA',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt9d5ea045bad3b067/677eab0ec9370e6446229537/Finish_Quantum_Finish_PL.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produkty/detergenty/kapsulki/quantum/60-lemon/',
        text: 'SPRAWDŹ QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt50c6add3c012a00f/677eab0eb67955d9b187adde/tough-on-grease-outline-red.webp',
          name: 'SKUTECZNY NA TŁUSZCZ',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishProductCardsMockUA = {
  cards: [
    {
      productName: 'FINISH ULTIMATE',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt535ec2a545e36726/677eab0ea53bf60c2ece270c/Finish_Ultimate_Finish_PL.webp',
      cardStyle: 'dark-blue',
      button: {
        link: '/produkty/detergenty/kapsulki/ultimate/40-fresh/',
        text: 'Більше про ULTIMATE',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blte599822b028d68d8/677eab0e966e6917d5fe05fd/dried-on-stains-outline-red_(1).webp',
          name: 'Проти затверділих протягом <strong>48</strong> годин залишків їжі',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt50c6add3c012a00f/677eab0eb67955d9b187adde/tough-on-grease-outline-red.webp',
          name: 'Ефективний проти жиру',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH ULTIMATE PLUS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt32e4d8727a4e0322/677eab0e43d191501d613128/Finish_Ultimate_Finish_Plus_PL.webp',
      cardStyle: 'black',
      button: {
        link: '/produkty/detergenty/kapsulki/ultimate-plus/45-lemon/',
        text: 'Більше про наш найкращий ULTIMATE PLUS',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltfffcfb30fa4a7d54/677eab0eae593823833f031f/burnt-on-stains-outline-gold.webp',
          name: 'Усуває пригорілі залишки їжі',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/bltdc26c3a34f7107f0/677eab0e5fc75374bf521a08/tough-on-grease-gold-outline-gold.webp',
          name: 'Ефективний проти жиру',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt31d1a3b88b112564/677eab0ee634b6fe5829a8cf/extra-cleaning-power-outline-gold_(1).webp',
          name: '<strong>3X</strong><br/>більш інтенсивна формула',
          styleType: 'text-on-image',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH QUANTUM',
      image:
        'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt9d5ea045bad3b067/677eab0ec9370e6446229537/Finish_Quantum_Finish_PL.webp',
      cardStyle: 'light-blue',
      button: {
        link: '/produkty/detergenty/kapsulki/quantum/60-lemon/',
        text: 'Більше про QUANTUM',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt50c6add3c012a00f/677eab0eb67955d9b187adde/tough-on-grease-outline-red.webp',
          name: 'Ефективний проти жиру',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'Zapisz się do newslettera i odbierz kod rabatowy - 10 zł na zakupy Finish',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt0cb876247fdd67ca/679b6b143fa7de369874ef5b/image-finish-products-pop-up.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt0cb876247fdd67ca/679b6b143fa7de369874ef5b/image-finish-products-pop-up.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'Dziękujemy',
    description: '',
    closeButton: {
      label: 'Strona glowna',
      redirectUrl: '/',
    },
  },
  form: {
    id: 'df44d8e7-e7e2-4743-b6fc-6d7acca9690a',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'To pole nie może być puste.',
  },
};
